<template>
    <r-container>
        <r-row>
            <r-col class="col-6 text-center">{{ $t("theme",'renusify') }}</r-col>
            <r-col class="col-6 text-center">
                <r-switch flat v-model="night"></r-switch>
            </r-col>
        </r-row>
        <r-row>
            <r-col class="col-6 text-center">{{ $t("language",'renusify') }}</r-col>
            <r-col class="col-6">
                <r-select
                        :label="$t('language','renusify')"
                        :items="langs"
                        @update:model-value="switcher"
                        btn
                        combo
                        v-model="language"
                ></r-select>
            </r-col>
        </r-row>
        <r-row>
            <r-col class="col-6 text-center">{{ $t("logout",'renusify') }}</r-col>
            <r-col class="col-6">
                <r-btn @click.prevent="logout" icon v-if="$r.store.user.login">
                    <r-icon v-html="$r.icons.logout"></r-icon>
                </r-btn>
            </r-col>
        </r-row>
    </r-container>
</template>

<script>
    export default {
        name: "setting",
        data() {
            return {
                night: false,
                show: true,
                language: [],
                color_toolbar: "primary",
                RTL: this.$r.rtl,
                langs: [
                    {value: "en", name: "English"},
                    {value: "fa", name: "فارسی", rtl: true}
                ]
            };
        },
        created() {
            if (!this.$r.icons.logout) {
                this.$r.icons.logout = '<path fill="currentColor" d="m16.56 5.44l-1.45 1.45A5.969 5.969 0 0 1 18 12a6 6 0 0 1-6 6a6 6 0 0 1-6-6c0-2.17 1.16-4.06 2.88-5.12L7.44 5.44A7.961 7.961 0 0 0 4 12a8 8 0 0 0 8 8a8 8 0 0 0 8-8c0-2.72-1.36-5.12-3.44-6.56M13 3h-2v10h2"/>'
            }
        },
        beforeMount() {
            if (this.$storage.has("rtl")) {
                this.RTL = this.$storage.get("rtl");
            } else {
                this.RTL = true;
            }
            if (this.$storage.has("theme.night")) {
                this.night = this.$storage.get("theme.night");
            }
            const lang = this.$storage.get("lang");
            if (lang) {
                this.$r.lang = lang;
            }
            const index = this.$helper.searchArray(this.langs, "value", this.$r.lang);
            this.language = this.langs[index];
        },
        watch: {
            night() {
                this.$storage.set("theme.night", this.night);
                this.$r.dark = this.night;
            },
            RTL() {
                this.$storage.set("rtl", this.RTL);
                this.$r.rtl = this.RTL;
            }
        },
        methods: {
            logout() {
                this.$axios.post("/user/logout").then(() => {
                    this.$r.store.user = {
                        login: false,
                        info: {}
                    };
                });
                this.$router.push({name: "base"});
            },
            switcher(lang) {
                if (!lang) {
                    return null;
                }
                this.RTL = lang.value === "fa";
                this.switchLang(lang.value);
                this.$storage.set("lang", lang.value);
                this.$r.lang = lang.value;
            },
            switchLang(lang) {
                if (lang in this.$translate.messages) {
                    this.show = false;
                    this.$translate.local = lang;
                    this.show = true;
                } else {
                    this.loadLocaleMessage(lang);
                }
            },
            loadLocaleMessage(locale) {
                return this.$axios
                    .get(`/translate/${this.$r.package}/${locale}`)
                    .then(res => {
                        if (res.data.length === 0) {
                            this.$r.toast("lang_empty", "warning");
                        } else {
                            const lang = {};
                            for (let i = 0; i < res.data.length; i++) {
                                lang[res.data[i].key] = res.data[i][locale];
                            }
                            this.show = false;
                            this.$storage.set(`msg-${this.$r.package}`, lang);
                            this.$translate.local = locale;
                            this.$translate.setMessages(lang, locale);
                            this.show = true;
                        }
                    });
            }
        }
    };
</script>
